body,html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Blog */
.blog {
  color: #a2a2c7;
  font-size: 60px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

/* Stay up to date with current initiatives */
.stay-updated {
  color: white;
  font-size: 64px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 72px;
  word-wrap: break-word;
}

/* READ MORE */
.read-more {
  color: black;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.6px;
  word-wrap: break-word;
}

/* Services */
.services,
.blogx {
  color: #ded8d8;
  font-size: 30px;
  font-family: "Roboto";
  font-weight: 700;
  letter-spacing: 3px;
  word-wrap: break-word;
}

/* Community Minded Consulting Firm */
.community {
  color: white;
  font-size: 64px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 72px;
  word-wrap: break-word;
}

/* E3 Strategic Solutions empowers organizations to reach their full potential with professional planning services */
.empowers-organizations {
  color: white;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 32px;
  word-wrap: break-word;
}

/* ABOUT US */
.about-us {
  color: white;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.6px;
  word-wrap: break-word;
}

/* Book Now */
.book-now {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 4px;
  word-wrap: break-word;
}

/* Terms of Use */
.terms-of-use {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* Privacy */
.privacy {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* Contact */
.contact {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* E3 Strategic Solutions */
.e3-strategic-solutions {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* Web Design: PM Daybreak Designs */
.web-design {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* About Us */
.about-us-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

/* Services (Secondary) */
.services-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

/* Blog (Secondary) */
.blog-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

/* Contact (Secondary) */
.contact-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

.Title {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.8);
}

.ButtonAbout:hover,
.ButtonServices:hover,
.ButtonBlog:hover {
  transform: scale(1.2);
  transition: transform ease-in-out 0.3s;
  cursor: pointer;
}

.ListitemDesktopNa,
.Logos,
.ListitemDesktopNaActive {
  cursor: pointer;
}

.ListitemDesktopNa:hover {
  background-color:#d9cfbf !important;
}
.ListitemDesktopNa:hover .Label {
  color: black !important;
}


.Button:hover {
  transform: scale(1.02);
  transition: transform ease-in-out 0.5s;
  background-color: white !important;
  cursor: pointer;
}

.Button:hover .Label {
  color: black !important;
}

.Button:hover .ReadMore {
  color: black !important;
  transition: color 0.6s ease;
}

.Button {
  transition: transform ease-in-out 0.5s; 
  z-index: 4;
  padding: 8px 20px; /* Adjust padding for the button */
  background: rgb(130, 117, 78);
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px; /* Adjust border radius for a less oval shape */
  border: 2px black solid;
  display: inline-flex;
  align-items: center;
  max-width: 200px;
}

.Label {
  color: white;
  font-size: clamp(16px, 2vw, 16px); /* Adjust font size */
  font-family: "Roboto";
  font-weight: 700;
  letter-spacing: 1.6px;
  word-wrap: break-word;
  cursor: pointer;
}

.Content1 > .SectionTitle, .Content1 > .SectionTitle  > .ContentX {
   height: auto !important;
   min-height: 180px;
}

@media (max-height: 500px) and (max-width: 950px) {
  .InitialAbout {
    display: none;
  }
  .Lead {
    font-size: 15px !important;
  }
  .Row {
    display: block !important;
  }
  .Servicesdesktop{
    top: 10vh !important;
  }
}

.Row {
  align-self: stretch;
  justify-content: center;
  align-items: flex-start;
  gap: 42.67px;
  display: inline-flex;
  max-width: 90vw;
}

.ContentX {
  align-self: "stretch";
  flex-direction: "column";
  justify-content: "flex-start";
  align-items: "flex-start";
  display: "flex";
}

@media (max-height: 500px) and (max-width: 916px) {
  .Row {
    display: block !important;
  }
  .MySkill {
    top: 200vh !important;
    margin-top: 70px;
  }
  .Content1 {
    max-height: 200px;
    max-width: 500px;
  }
  .Card {
    max-height: 600px;
    max-width: 500px;
    margin-bottom: 70px;
  }
  .Row.Card {
    max-width: "200px" !important;
  }
  .Row.Content1 {
    max-width: "200px" !important;
  }
}

@media (min-height: 501px) and (min-width: 916px) {
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "25vw" !important;
  }
}

.Blog {
  max-width: 100%;
  height: auto;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3vh;
  display: inline-flex;
  padding: "0px 3rem";
}




/*SERVICES PAGE*/

/* Servicecards1 */
.Servicecards1 {
  display: flex;
  justify-content: space-around;
  /* Adjust spacing on the sides */
}

.Frame2230 {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap */
  gap: 20px; /* Space between cards */
  justify-content: center; /* Center the cards */
}

.Servicecard {
  border: solid #5f5fb0;
  width: 100%; /* Default to 100% width */
  max-width: 300px; /* Maximum width for each card */
  height: 350px; /* Increased height for taller cards */
  position: relative; 
  box-shadow: 5px 5px 20px -10px #5f5fb0;
  border-radius: 30px;
  overflow: hidden; /* Ensure content doesn't overflow */
}

/* Responsive layout adjustments */
@media (max-width: 600px) { /* Small screens */
  .Servicecard {
    width: 100%; /* 1 per row */
    margin-top: 20px;
  }
  .CardTextx{
    margin-top:0px !important;
    display: flex; /* Enable flexbox for vertical alignment */
    flex-direction: column; 
    justify-content: center;
    }
}

@media (min-width: 601px) and (max-width: 1180px) { /* Medium screens */
  .Servicecard {
    width: calc(50% - 10px);/* 2 per row */
    margin-top: 50px;
   align-items: center;
  }

}

@media (min-width: 1181px) { /* Large screens */
  .Servicecard {
    width: calc(25% - 25px); /* 4 per row */
    /* margin:5px  */
  }
}

@media (max-height: 500px) {
  .Servicecard {
    height: 300px !important;
  }
}

@media (min-width: 1181px) {
  .Servicecard {
    height: 400px !important;
  }
}


/* For mobile devices */
@media (max-width: 1180px) {
  .Frame2230 {
    /* flex-wrap: wrap; */
    max-width: 100%;
    margin:10px;
    justify-content: space-between;
  }

  .Frame1 {
   
      padding-bottom: 1rem;
    
  }
}

.CardImage {
  width: 100%;
}

.cardpic {
  width: 100%;
  height: auto;
  border-top-left-radius: 20px;
  object-fit: cover;
}

.CardTextx {
  width: 100%;
  color: black;
  font-family: Roboto;
  font-weight: 400;
  box-sizing: border-box; /* Include padding in width calculation */
  text-align: center;
}

.Group5 {
  position: absolute;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  padding: 10px 0;
}

.TitleSer {
  color:black;
  font-family: "Bowlby One SC", sans-serif;
  margin-top: 10px; /* Remove default margin */
  text-align: center;
}
@media (max-width:1180px){
  .TitleSer {
    font-size: clamp(20px, 2.5vw, 25px) !important;
    font-weight:bold;
    margin:6px;
  }
  .CardTextx{
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: clamp(20px, 2vw, 20px) !important;
  }
}

@media (min-width:1181px){
  .TitleSer {
    font-size: 20px !important;
    font-weight: bold;
    margin:10px;
  }
  .CardTextx{
    font-size: 16px !important;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;  }
}

.Servicecard .Button {
  position: absolute;
  justify-content: center;
  bottom: 10px;
  width: 50%;
  text-align: center;
  margin-top: 2px; /* Adjust margin as needed */
  left: 50%;
  transform: translateX(-50%);
}

.ReadMore {
  color: white;
  font-size: clamp(14px, 2vw, 14px);
  font-family: Roboto, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 1px 2px; /* Adjust padding as needed */
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 950px) {
  .BodyX {
    height: 944.38px;
  }
}

@media (max-width: 950px) {
  .BodyX {
    height: 644.38px;
  }
  .Servicesx {
    top: 57px !important;
  }
}

/*BLOG*/

.BlogX,
.ContactX {
  position: absolute;
  background: white;
  top: 0px;
}

@media (min-width:576px){
  .Contact2 {
    top: 70px !important;
  }
}

.ComingSoon {
  font-family: "Roboto", sans-serif;
  color: black;
  /* Additional styles can be added as needed */
}

.ContactForm {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.FormGroup {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #5f5fb0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #3a3ac0;
}

.ContactForm {
  top: 17vh;
  position: absolute;
}
@media(min-Width:576px){
.ContactForm {
  top: 10vh !important;
  position: absolute;
}
}


@media (max-width: 950px) {
  .ContactX .Frame1 {
    top: 57px;
  }

}

@media (max-width: 453px) {
  .contact-footer {
    display: none !important;
  }

  .Footerprivacy, .HeadingName {
    gap: 4px !important;
    justify-content: space-evenly !important;
    width: 100%;
  }

}

@media (max-width: 351px) {

  .HeadingName {
    .HeaderMenuDefault .Label, .MenuItemDefault .Label{
      font-size: 8px !important; 
    }
  }
  
}
/* @media (min-width: 950px) {
  .ContactX .Frame1 {
    top: 100px;
  }

    .Footerprivacy {
    .HeaderMenuDefault {
      .Label, .Label > a {
        font-size: 8px !important;
      }
  
    }
  }
} */

/*MODAL*/

@media (min-width:576px){
.modalImg{
object-position: center -140px;
}
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.5s ease-out, opacity 0.5s ease-out;
  transition: -webkit-transform 0.5s ease-out, opacity 0.5s ease-out;
  -o-transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  transform: translate(0, -50px);
  opacity: 0;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
  opacity: 1;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  height: fit-content;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-body img {
  border-radius: 20px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 1180.98px) {

  .overlays-container-mobile {
    display: block !important;
  }

  #Homedesktop1,#Homedesktop2,#Homedesktop3{
    display: none;
  }

  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .text-content3-mobile{
    transition: all 2s;
  }

  .AboutUs {
    display: none !important;
  }

  .AboutUs-mobile {

    display: block !important;

    .Place {
      margin-top: 1rem;
    }

    .Blog {
      top: 0 !important;
      position: relative !important;
      left: 0 !important;
      max-width: 100% !important;
      height: auto !important;
    }


  }

  .Services {
    display: none;
  }

  .Services-mobile {
    display: flex !important;

    .Servicesx {
      top: 0px !important;

      .Home {

        .CardTextz {
          padding-right: 0px !important;
        }
      }

      
    }

    .BodyX {
      .Servicecards1 {

        .Frame2230 {
          display: flex !important;
          flex-direction: column !important;
          gap: 40px !important;
          margin-top: 2rem;
      
          .Servicecard {
             max-width: 100%;
             width: 100%;
          }
        }

        .Frame2230 .Servicecard {
          display: flex !important;

          .cardpic {
            height: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          .CardTextx {
            margin-top: 50px;
          }

          
        
        }
      }
    }
    
    
  }

 
}
/* mine */
/* @media (max-width: 425px){ */
  @media (max-width: 573px){
  .Aboutus {

    .Text, .content-name, .E3StrategicSolutions {
      color: black !important;
      text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.8)  !important;
    }
  }
}

@media (min-width: 412px) and (max-width: 915px ) {
  .Footerdesktop {
    height: 50px !important;
  }

}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.modal-open {
  overflow: hidden; /* Prevent scrolling when modal is open */
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  backdrop-filter: blur(5px); /* Add blur effect to the background */
  z-index: 7; /* Ensure the overlay is on top of other content */
}

.color-white {
  color: white;
}

/* Small devices such as large phones (640px and up) */
/* @media only screen and (min-width: 40em) {...} */

/* Medium devices such as tablets (768px and up) */
/* @media only screen and (min-width: 48em) {...} */

/* Large devices such as laptops (1024px and up) */
/* @media only screen and (min-width: 64em) {...} */

/* Largest devices such as desktops (1280px and up) */
/* @media only screen and (min-width: 80em) {...} */

@media (min-width: 757px) {
  #NavlogoMobile {
    display: none;
  }
}

@media only screen and (max-width: 1180px){
  #Navmenudesktop {
    display: none !important;
  }
  .Navlogo{
    display: none !important;
  }
  #NavlogoMobile{
    display: flex !important;
    position:absolute;
    z-index:9;
    margin:10px;
  }

  #NavlogoMobile img {
    height: 7vh;
    margin-top: 0vh;
    position: absolute;
    left: 10px;
    top:0px;
    cursor: pointer;
  }

  #mobile-menu {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    position: relative;
  }


  #burger-menu {
    cursor: pointer;
	height: 27px;
	width: 35px;
	overflow: visible;
	position: relative;
	z-index: 2;
  background-color: transparent;
  
    /* // THE LINES */
    span,
    span:before,
    span:after {
      background-color: #fff;
      display: block;
      height: 2px;
      opacity: 1;
      position: absolute;
      transition: 0.3s ease-in-out;
    }
  
    span:before,
    span:after {
      content: '';
    }
  
    /* // TOP */
    span:before {
      left: 0px;
		top: -10px;
		width: 35px;
    }
  
    /* // MIDDLE */
    span {
      left: 0;
			top: 13;
			width: 35px;
    }
  
    /* // BOTTOM */
    span:after {
      left: 0px;
      top: 10px;
      width: 35px;
    }

    /* // ROTATION ON CLICK */
    &.close {
      /* // TOP */
      span:before {
        top: 0px;
        transform: rotate(90deg);
        width: 35px;
      }
  
      /* // MIDDLE */
      span {
        transform: rotate(-45deg);
        top: 13px;
        width: 35px;
      }
  
      /* // BOTTOM */
      span:after {
        top: 0px;
        left: 0;
        transform: rotate(90deg);
        opacity: 0;
        width: 0;
      }
    }

  }

  #menu-content :not(:last-child) {
    padding-bottom: 20px;
  }

  .HoverOverlay2, .HoverOverlay3, .Overlay2, .Overlay3, .Overlay1, .Rectangle158, .Rectangle158, .Footercompany, .Navmenudesktop {
    display: none !important;
  }

  #HoverOverlay3-mobile, #HoverOverlay2-Mobile{
    display: inline-flex !important;
    border: "1px solid rgb(140, 132, 132)";
  }

  .Rectangle158 {
    width: 100vw !important;
    height: 25vh !important;
  }

  .ButtonBlog-mobile, .ButtonServices-mobile {
    transform: scale(1.2);
  }

  .Footerprivacy {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 0px !important;
    width: 100% !important;

  }

  .Lead {
    max-width: 100% !important;
  }

  .active-menu-mobile {
    background-color: rgb(130, 117, 78) !important;
    
    .Label {
      color: white !important;
    }
  }

  .Aboutus {

    #about-button {
      transform: translateY(200px) !important;
    }

    .Row {
      flex-direction: column;
      /* gap: 0px; */

      .ContentX, .SectionTitle {
        height: auto !important;
      }

    }

    .MySkill {
      height: 100% !important;
      left: 0 !important;
      padding: 20px !important;
      position: relative !important;

      .Row > .last {
        max-width: 100% !important;
      }
    }

  }

  #about-button{
    position:absolute;  
  } 

/* Landscape orientation */
@media (orientation: landscape) {
  .Samplebio21 {
    left: 50%;
    transform: translateX(-10%);
    top: 23vh;
  }
  /* //FIXXX */
  .MySkill {
    position: absolute !important;
    top: 5vh; /* Position it higher by setting a smaller value */
    bottom: auto; /* Ensure bottom positioning does not conflict */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56.67px;
}

}

/* Portrait orientation */
@media (orientation: portrait) {
  .Samplebio21 {
    left: 50%;
    transform: translateX(-50%);
    top: 23vh;
  }
  #about-button{
  top:40vh;
  }
}


  /* .last-form-group {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    } */

      /* .ContactForm {
        background-color: #A2A2C7 !important;
        width: 90% !important;
      }

      .book-consultation{ 
        margin-bottom: 60px !important;
      } */
 

}

.fade {
  transition: opacity 0.5s ease;
  opacity: 0.5; /* Adjust the opacity value as needed */
}

@media (max-width:641px){
.ContactNav{
  display:block important!
}
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media (min-width: 300px) and (min-height:800px){
  .Experience {
    font-size: 20px !important;
  }
  .content-name {
    font-size: 35px !important;
  }
}

/* .BlogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 40px;
  background: white;
} */

.contentContainer {
  padding-top: 80px;
  padding-bottom: 80px;

}

  @media(min-width:601px){
    .Bloglogo{
    display:none;
    }
  }
  .x{
  color:#f0ece7
  }

/*LOADING SCREEN*/

/* Pulsing Heart Animation */
@keyframes pulseheart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pulsing-heart {
  width: 300px;
  height: 300px;
  background: url('../public/loading4.svg') no-repeat center center;
  background-size: contain;
  animation: pulseheart 1s infinite;
  position: absolute;
   
  /* clip-path: inset(30% 0 30% 0); */
}



.logo {
  width: 250px; /* Adjust logo size */
  animation: fadeInLogo 3s infinite;
  margin-top: 20px; /* Space between loader and logo, adjust as needed */
  position: absolute; 
}

@keyframes fadeInLogo {
  0% {
    opacity: 0; /* Start fully transparent */
  }
  100% {
    opacity: 1; /* End fully visible */
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 1s ease, visibility 1s ease;
  animation: fadeOut 2s forwards; /* Adjusted animation timing */
  animation-delay: 1s; 
}

.loading-screen.hidden {
  opacity: 0;
  visibility: hidden;
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
}

.subscribe-button{
  animation: pulse 2s infinite;
}


.subscribe-button:hover,.donate-button:hover{
  transform: scale(1.1) !important;
  background-color:white !important;
  color:black !important;
}